<script setup>
import { defineAsyncComponent } from 'vue';

const CollectionsPage = defineAsyncComponent(() => import('~/pages/collections.vue'));
const dynamicCollection = useState('dynamicCollection', () => null);
definePageMeta({
  middleware: ['catch-all']
});
</script>
<template>
  <div v-if="dynamicCollection">
    <CollectionsPage :prefetchedCollection="dynamicCollection" />
  </div>
</template>
